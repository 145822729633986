<!-- 人核订单 -->
<template>
  <div class="muw-order-list-container">
    <van-tabs
      class="nav-tabs"
      v-model="activeTab"
      @change="onTabChange"
      swipe-threshold="6"
      color="#FF8F2B"
      line-height="4"
      sticky
      title-active-color="#FF8F2B"
    >
      <van-tab v-for="(item, index) in navList" :key="index" :title="item.name" :name="item.value"></van-tab>
    </van-tabs>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
      >
        <template v-if="orderList.length > 0">
          <div class="order-item" v-for="(item, index) in orderList" :key="index">
            <div @click="toDetail(item.id)">
              <p class="title">
                <span class="title-txt van-ellipsis">{{ item.productName }}</span>
                <!-- guarantee  surrender-->
                <span v-if="item.statusName == '已撤销'" class="status-txt surrender-color">{{ item.statusName }}</span>
                <span v-else class="status-txt orange-color">{{ item.statusName }}</span>
              </p>
              <p class="time">投保时间：{{ item.insuredTime }}</p>
              <div class="content">
                <p class="name">
                  <span class="label">投/被保人：</span>
                  <span class="value">{{ item.appntName }}/{{ item.insuredName }}</span>
                </p>
                <p class="ins-period" v-if="item.insStartTime && item.insEndTime">
                  <span class="label"> 保险期间：</span>
                  <span class="value">{{ item.insStartTime ? item.insStartTime : "--" }} 至 {{ item.insEndTime ? item.insEndTime : "--" }}</span>
                </p>
                <p class="premium">
                  <span class="label"> 保费：</span>
                  <span class="value">{{ item.premium }}元 </span>
                </p>
                <!-- <div v-if="item.manual.manualResult" class="underwriting-desc orange-gradient">
                  <p class="van-ellipsis"># {{ item.manual.manualResult }}</p>
                  <van-icon
                    v-if="item.manual.manualResult.length > 19"
                    name="question-o"
                    class="question"
                    @click.stop="showmanualResult(item.manual.manualResult)"
                  />
                </div> -->
                <table class="letter-table">
                  <tr>
                    <th>核保对象</th>
                    <th>核保状态</th>
                    <th>核保结论</th>
                  </tr>
                  <tr v-for="(sItem, index) in item.newManual" :key="index">
                    <td>{{ sItem.useType == 1 ? item.appntName : item.insuredName }}</td>
                    <td>{{ sItem.statusContent }}</td>
                    <td>{{ sItem.conclusionContent }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="btn-group">
              <!-- .orange-btn -->
              <!--  核保完成 -->
              <div class="right">
                <template v-if="item.manual.status == 5">
                  <button v-if="item.manual.exclusionUrl" class="btn orange-btn" @click.stop="toUrl(item.manual.exclusionUrl)">确认并支付</button>
                </template>
                <!--  已撤销 -->
                <template v-else-if="item.manual.status == 6">
                  <button class="btn" @click="deleteOrder(item.id)">删除</button>
                </template>
                <!-- 核保中 -->
                <template v-else>
                  <button v-if="item.manual.exclusionUrl" class="btn orange-btn" @click.stop="toUrl(item.manual.exclusionUrl)">补充人核资料</button>
                </template>
                <button  class="btn" @click.stop="$router.push(`/muwOrderDetail?id=${item.id}`)">人核详情</button>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <van-empty></van-empty>
        </template>
      </van-list>
    </van-pull-refresh>
    <van-overlay class="overlay-loading" :show="dataLoading" @click="dataLoading = false">
      <div class="overlay-wrapper" @click.stop>
        <van-loading size="24" />
        <p style="color: #fff; margin-left: 0.5em">加载中...</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getManualList, getNavTab, deleteOrder } from "../../api/api"
export default {
  data() {
    return {
      activeTab: 0,
      refreshing: false,
      loading: false,
      error: false,
      finished: false,
      orderList: [],
      navList: [],
      page: 1,
      perPage: 3,
      dataLoading: false,
    }
  },
  created() {
    this.getNavTab()
    this.onLoad()
  },
  methods: {
    onTabChange() {
      this.page = 1
      this.orderList = []
      this.finished = false
      this.refreshing = false
      this.loading = true
      console.log("tabchange")
      this.onLoad()
    },
    async getNavTab() {
      const res = await getNavTab({ type: 2 })
      this.navList = res.data.list
    },
    async onLoad() {
      if (this.refreshing) {
        this.orderList = []
        this.refreshing = false
      }
      this.dataLoading = true
      let res = await getManualList({
        page: this.page,
        perPage: this.perPage,
        status: this.activeTab,
      })
      this.dataLoading = false
      this.page++
      this.loading = false
      this.orderList = this.orderList.concat(res.data.list)
      if (this.orderList.length >= res.data.totalNum) {
        this.finished = true
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      this.page = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    toDetail(id) {
      this.$router.push(`/orderDetail?id=${id}&source=${this.$route.path}`)
    },
    toUrl(url) {
      if (url) {
        window.location.href = url
      } else {
        this.$toast("请联系管理员！")
      }
    },
    async deleteOrder(orderId) {
      await deleteOrder({ orderId })
      this.$toast("删除成功")
      this.$router.push("/muwOrderList")
    },
    showmanualResult(result) {
      this.$dialog
        .alert({
          message: result,
        })
        .then(() => {
          // on close
        })
    },
  },
}
</script>
<style lang="less" scoped>
.muw-order-list-container {
  background-color: #fbfbfb;
  width: 100%;
  height: 100%;
  .letter-table {
    width: 100%;
    border-collapse: collapse;
    color: #606266;
    margin-top: 10px;
    font-size: 24px;

    th,
    td {
      padding: 12px;
      text-align: left;
      border: 1px solid #ebeef5;
    }

    th {
      background-color: #f7f7f7;
      font-weight: normal;
    }
  }
  .surrender-color {
    color: #98999a;
  }
  .guarantee-color {
    color: #5ba5fd;
  }
  .orange-color {
    color: #fe8a3d;
  }
  /deep/.nav-tabs {
    .van-tabs__wrap {
      height: 92px;
    }
    .van-tab {
      height: 92px;
      padding: 29px 20px 0px;
      align-items: stretch;
      font-size: 34px;
      line-height: 48px;
      color: #333;
    }
  }

  /deep/.van-pull-refresh {
    background-color: #fbfbfb;
    padding: 0 24px;
    height: calc(100vh - 92px) !important;
    overflow: auto !important;
  }
  /deep/ .van-list {
    height: auto !important;
  }
  .order-item {
    width: 702px;
    margin-top: 30px;
    padding: 30px 24px;
    font-size: 30px;
    background-color: #fff;
    box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-txt {
        width: 374px;
        height: 48px;
        font-size: 34px;
        font-weight: 500;
        color: #333333;
        line-height: 48px;
      }
      .status-txt {
        height: 40px;
        font-size: 28px;

        line-height: 40px;
      }
    }
    .time {
      height: 37px;
      margin-top: 4px;
      font-size: 26px;
      color: #777777;
      line-height: 37px;
    }
    .content {
      position: relative;
      overflow: hidden;
      width: 654px;
      margin-top: 20px;
      padding: 24px;
      background-color: #fafafa;
      border-radius: 20px;

      .label {
        color: #777;
      }
      .value {
        color: #333;
      }
      p {
        height: 42px;
        line-height: 42px;
        &:nth-child(n + 2) {
          margin-top: 12px;
        }
      }
      .seal {
        position: absolute;
        top: -14px;
        right: -14px;
        width: 100px;
        height: 100px;
      }
      .gray-gradient {
        color: #777;
        background: linear-gradient(264deg, rgba(247, 247, 247, 0) 0%, #ededed 100%);
      }
      .orange-gradient {
        color: #fe8a3d;
        background: linear-gradient(264deg, rgba(255, 247, 242, 0) 0%, #fff3e8 100%);
      }
      .underwriting-desc {
        display: flex;
        align-items: center;
        width: 600px;
        height: 60px;
        margin-top: 20px;
        padding: 10px 0 10px 16px;
        font-size: 28px;
        border-radius: 10px;
        p {
          width: 520px;
        }
      }
    }
    .btn-group {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .more {
        color: #777777;
        height: 37px;
        font-size: 26px;
        color: #777777;
        line-height: 37px;
      }
      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
      .btn {
        min-width: 168px;
        padding: 0 24px;
        height: 68px;
        margin-left: 16px;
        border-radius: 34px;
        border: 1px solid #cecfd0;
        text-align: center;
        line-height: 68px;
        font-size: 26px;
        color: #333333;
        background-color: #fff;
      }
      .orange-btn {
        border-color: #fe8a3d;
        color: #fe8a3d;
      }
    }
  }
}
</style>
